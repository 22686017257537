<template>
  <v-app>
    <v-main>
      <v-container class="py-0">
        <booking-form
          :property="property"
          :widget-key="widgetKey"
          :theme="theme"
          :inline="inline"
          :title="title"
        />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import BookingForm from '@/components/BookingForm'

export default {
  components: { BookingForm },
  props: {
    property: {
      type: String,
      default: process.env.VUE_APP_PROPERTY,
      required: !process.env.VUE_APP_PROPERTY
    },
    widgetKey: {
      type: String,
      default: process.env.VUE_APP_WIDGET_KEY,
      required: !process.env.VUE_APP_WIDGET_KEY
    },
    theme: {
      type: String,
      default: 'blue'
    },
    locale: {
      type: String,
      default: 'auto'
    },
    inline: Boolean,
    title: {
      type: String,
      default: ''
    }
  },
  created () {
    const currentLocale = this.locale === 'auto' ? navigator.language : this.locale
    this.$i18n.locale = currentLocale
    this.$vuetify.lang.current = currentLocale
    this.$axios.defaults.headers.common['X-Locale'] = currentLocale
  }
}
</script>

<style>
.v-application--wrap {
  min-height: auto !important;
}
</style>
