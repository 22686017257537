import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from './plugins/axios'
import i18n from './plugins/i18n'
import vueCustomElement from 'vue-custom-element'

Vue.config.productionTip = false
Vue.use(vueCustomElement)
Vue.prototype.$axios = axios
store.$axios = axios

App.store = store
App.vuetify = vuetify
App.i18n = i18n

Vue.customElement('a24-booking-widget', App, {
  beforeCreateVueInstance (RootComponentDefinition) {
    if (process.env.NODE_ENV === 'production' && !document.getElementById('a24-booking-widget-css-link')) {
      const link = document.createElement('link')
      link.id = 'a24-booking-widget-css-link'
      link.href = process.env.VUE_APP_CDN_BASE_URL + '/css/app.css'
      link.rel = 'stylesheet'
      link.type = 'text/css'
      document.body.appendChild(link)
    }
    return RootComponentDefinition
  }
})
