import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import uk from 'vuetify/es5/locale/uk'
import en from 'vuetify/es5/locale/en'
import ru from 'vuetify/es5/locale/ru'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg'
  },
  lang: {
    locales: { uk, en, ru },
    current: 'en'
  }
})
