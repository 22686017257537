<template>
  <div>
    <v-text-field
      :value="formattedRange"
      :label="$t('dates')"
      :prepend-icon="icons.mdiCalendarMonth"
      :error-messages="errors"
      :rules="menu ? [] : rules"
      readonly
      @click:prepend="openMenu"
      @click="openMenu"
    >
      <template
        v-if="requestBtn"
        #append-outer
      >
        <v-btn
          outlined
          :color="theme"
          :disabled="value.length !== 2"
          @click="onRequest"
        >
          {{ $t('request') }}
        </v-btn>
      </template>
    </v-text-field>
    <v-menu
      ref="menu"
      v-model="menu"
      :position-x="positionX"
      :position-y="positionY"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="290"
      offset-y
    >
      <v-date-picker
        :value="value"
        :color="theme"
        :allowed-dates="allowedDates"
        no-title
        scrollable
        range
        @input="onInput"
      >
        <v-btn
          color="blue-grey"
          icon
          @click="menu = false"
        >
          <v-icon v-text="icons.mdiClose" />
        </v-btn>

        <v-spacer />

        <v-btn
          text
          color="blue-grey"
          @click="onClear"
        >
          {{ $t('clear') }}
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { mdiCalendarMonth, mdiClose } from '@mdi/js'
import { eachDayOfInterval, format } from 'date-fns'
import { uk, ru } from 'date-fns/locale'

const dateFnsLocales = { uk, ru }

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    theme: {
      type: String,
      required: true
    },
    allowedDates: {
      type: Function,
      default: () => true
    },
    requestBtn: Boolean,
    errors: {
      type: Array,
      default: () => []
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      icons: {
        mdiCalendarMonth, mdiClose
      },
      menu: false,
      positionX: null,
      positionY: null
    }
  },
  computed: {
    sortedDates () {
      return [...this.value].sort((x, y) => x > y ? 1 : -1)
    },
    formattedRange () {
      if (this.value.length > 1) {
        return this.sortedDates
          .map(v => format(Date.parse(v), 'd MMM', { locale: dateFnsLocales[this.$i18n.locale] }))
          .join(' - ')
      }
      return ''
    }
  },
  watch: {
    value: {
      handler (val, oldVal) {
        if (val.length === 2) {
          const interval = { start: Date.parse(this.sortedDates[0]), end: Date.parse(this.sortedDates[1]) }
          const isUnavailableDatesSelected = eachDayOfInterval(interval).some(date => !this.allowedDates(date))
          if (isUnavailableDatesSelected) {
            this.onInput(oldVal)
          } else {
            this.menu = false
          }
        }
      },
      deep: true
    }
  },
  methods: {
    openMenu ({ clientX, clientY }) {
      this.menu = false
      this.positionX = clientX
      this.positionY = clientY
      this.$nextTick(() => {
        this.menu = true
      })
    },
    onInput (value) {
      this.$emit('input', value)
    },
    onRequest () {
      this.$emit('request', this.value)
    },
    onClear () {
      this.onInput([])
    }
  }
}
</script>
