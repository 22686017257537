<template>
  <div>
    <v-alert
      v-if="requestSuccessful"
      type="info"
      :icon="icons.mdiCheck"
      :color="theme"
      prominent
    >
      {{ $t('request_success_message') }}
    </v-alert>

    <template v-else>
      <request-form
        v-if="inline"
        :theme="theme"
        :loading="requestLoading"
        :title="title"
        :submit-name="$t('request')"
        @submit="onSendRequest"
      >
        <template #title>
          {{ title }}
        </template>

        <template #error-alert="{ message }">
          <v-alert
            color="red lighten-2"
            text
            dismissible
          >
            {{ message }}
          </v-alert>
        </template>

        <template #fields>
          <date-picker
            v-model="selectedDates"
            :theme="theme"
            :allowed-dates="allowedDates"
            :request-btn="false"
            :errors="dateErrors"
            :rules="[val => !!val || $t('field_required_error')]"
          />
        </template>
      </request-form>

      <date-picker
        v-else
        v-model="selectedDates"
        :theme="theme"
        :allowed-dates="allowedDates"
        request-btn
        @request="openDialog"
      />
    </template>

    <request-dialog
      v-model="dialog"
      :theme="theme"
      :loading="requestLoading"
      :title="title"
      @submit="onSendRequest"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { isPast, isToday, isWithinInterval } from 'date-fns'
import { mdiCheck } from '@mdi/js'
import DatePicker from '@/components/DatePicker'
import RequestDialog from '@/components/RequestDialog'
import RequestForm from '@/components/RequestForm'

export default {
  components: { DatePicker, RequestDialog, RequestForm },
  props: {
    property: {
      type: String,
      required: true
    },
    widgetKey: {
      type: String,
      required: true
    },
    theme: {
      type: String,
      default: 'primary'
    },
    inline: Boolean,
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialog: false,
      requestLoading: false,
      requestSuccessful: false,
      selectedDates: [],
      icons: {
        mdiCheck
      }
    }
  },
  computed: {
    ...mapState(['unavailableDates', 'errors']),
    dateErrors () {
      return this.errors.check_in || this.errors.check_out || []
    }
  },
  created () {
    this.loadDates({ widgetKey: this.widgetKey, property: this.property })
  },
  methods: {
    ...mapMutations(['setErrors', 'resetErrors']),
    ...mapActions(['loadDates', 'sendRequest']),
    openDialog (dates) {
      this.selectedDates = dates
      this.dialog = true
    },
    async onSendRequest (data) {
      this.requestLoading = true
      try {
        await this.sendRequest({
          widgetKey: this.widgetKey,
          property: this.property,
          params: { ...data, check_in: this.selectedDates[0], check_out: this.selectedDates[1] }
        })
        this.dialog = false
        this.requestSuccessful = true
      } catch (error) {
        this.setErrors(error.response.data)
      }
      this.requestLoading = false
    },
    allowedDates (date) {
      const dateObj = date instanceof Date ? date : Date.parse(date)
      if (isPast(dateObj) && !isToday(dateObj)) {
        return false
      }
      return !this.unavailableDates.some(v => {
        return isWithinInterval(dateObj, { start: Date.parse(v.date_from), end: Date.parse(v.date_to) })
      })
    }
  }
}
</script>
