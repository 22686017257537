<template>
  <v-dialog
    :value="value"
    max-width="600"
    persistent
    @input="$emit('input', value)"
  >
    <request-form
      ref="form"
      :loading="loading"
      :theme="theme"
      :title="title || $t('request_dialog_title')"
      @submit="onSubmit"
      @close="$emit('input', false)"
    >
      <template #error-alert="{ message }">
        <v-alert
          color="red lighten-2"
          text
        >
          <v-row align="center">
            <v-col class="grow">
              {{ message }}
            </v-col>
            <v-col class="shrink">
              <v-btn
                small
                outlined
                color="blue-grey darken-1"
                @click="$emit('input', false)"
              >
                {{ $t('select_another_dates') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </template>

      <template #actions>
        <v-btn
          color="blue-grey darken-1"
          text
          @click="$emit('input', false)"
        >
          {{ $t('cancel') }}
        </v-btn>
      </template>
    </request-form>
  </v-dialog>
</template>

<script>
import RequestForm from '@/components/RequestForm'

export default {
  components: { RequestForm },
  props: {
    value: Boolean,
    loading: Boolean,
    theme: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  watch: {
    value (val) {
      if (val) {
        this.$nextTick(() => this.$refs.form.reset())
      }
    }
  },
  methods: {
    onSubmit (form) {
      this.$emit('submit', form)
    }
  }
}
</script>
