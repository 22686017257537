import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    unavailableDates: [],
    errors: {}
  },
  mutations: {
    setUnavailableDates (state, payload) {
      state.unavailableDates = payload
    },
    setErrors (state, payload) {
      state.errors = payload
    },
    resetErrors (state) {
      state.errors = {}
    }
  },
  actions: {
    async loadDates ({ commit }, { widgetKey, property }) {
      const { data } = await this.$axios.get(`/widgets/${widgetKey}/apartments/${property}/bookings`)
      commit('setUnavailableDates', data)
    },
    async sendRequest ({ commit }, { widgetKey, property, params }) {
      await this.$axios.post(`/widgets/${widgetKey}/apartments/${property}/bookings`, { booking: params })
    }
  },
  modules: {
  }
})
