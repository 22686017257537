<template>
  <v-form
    ref="form"
    @submit="onSubmit"
  >
    <v-card flat>
      <v-card-title
        v-if="title"
        :class="`${theme}--text text--darken-2 py-0`"
      >
        {{ title }}
      </v-card-title>

      <v-card-text class="mt-3">
        <slot
          v-if="errors.dates && errors.dates.length"
          name="error-alert"
          :message="errors.dates.join(', ')"
        />

        <slot
          name="fields"
          :form="form"
        />

        <v-text-field
          v-model="form.name"
          :label="$t('input_name')"
          :rules="[val => !!val || $t('field_required_error')]"
          :error-messages="errors.name"
          :prepend-icon="icons.mdiAccountBoxOutline"
        />
        <v-text-field
          v-model="form.phone"
          :label="$t('input_phone')"
          :rules="[val => !!val || $t('field_required_error')]"
          :error-messages="errors.phone"
          :prepend-icon="icons.mdiCellphoneAndroid"
        />
        <v-select
          v-model="form.number_adults"
          :label="$t('input_guests_number')"
          :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          :prepend-icon="icons.mdiAccountGroupOutline"
        />
        <v-text-field
          v-model="form.email"
          label="E-mail"
          :hint="$t('hint_optional')"
          :error-messages="errors.email"
          :prepend-icon="icons.mdiEmailOutline"
        />
        <v-text-field
          v-model="form.comment"
          :label="$t('input_comment')"
          :hint="$t('hint_optional')"
          :error-messages="errors.comment"
          :prepend-icon="icons.mdiNoteTextOutline"
        />
      </v-card-text>

      <v-card-actions class="py-0">
        <v-spacer />

        <slot name="actions" />

        <v-btn
          :color="theme"
          :loading="loading"
          outlined
          @click="onSubmit"
        >
          {{ submitName }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mdiAccountBoxOutline, mdiCellphoneAndroid, mdiEmailOutline, mdiNoteTextOutline, mdiAccountGroupOutline } from '@mdi/js'
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    loading: Boolean,
    theme: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    submitName: {
      type: String,
      default: 'OK'
    }
  },
  data () {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        comment: ''
      },
      icons: {
        mdiAccountBoxOutline, mdiCellphoneAndroid, mdiEmailOutline, mdiNoteTextOutline, mdiAccountGroupOutline
      }
    }
  },
  computed: {
    ...mapState(['errors'])
  },
  methods: {
    ...mapMutations(['resetErrors']),
    onSubmit () {
      if (this.$refs.form.validate()) {
        this.$emit('submit', this.form)
        this.reset()
      }
    },
    reset () {
      this.resetErrors()
      this.$refs.form.resetValidation()
    }
  }
}
</script>
